import { CircularProgress } from "@mui/material";
import React from "react";

export function Loader({ sx }) {
  return (
    <CircularProgress
      sx={{
        ...sx,
        color: "#272950 !important",
      }}
    />
  );
}
