import React from "react";

export default function PlanIcon({ size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.99985 9.31257C5.86546 9.32515 5.7449 9.39863 5.67223 9.51231L3.58065 12.7678C3.48809 12.9119 3.48809 13.0966 3.58065 13.2408L5.67223 16.4962C5.75221 16.6201 5.88902 16.6952 6.03598 16.6964H10.2191C10.3661 16.6952 10.5029 16.6201 10.5829 16.4962L12.5469 13.4409H22.1498C22.2663 13.4421 22.3784 13.3967 22.4616 13.3147C22.5444 13.2327 22.5911 13.121 22.5911 13.0045C22.5911 12.8876 22.5444 12.7759 22.4616 12.6939C22.3784 12.6119 22.2663 12.5665 22.1498 12.5677H12.5469L10.5829 9.51236C10.5029 9.38854 10.3661 9.31343 10.2191 9.31262H6.03598C6.0238 9.31181 6.01162 9.31181 5.99985 9.31262L5.99985 9.31257ZM6.29093 10.1854H9.98281L11.8016 13.0045L9.98281 15.8236H6.29093L4.47217 13.0045L6.29093 10.1854Z"
        fill="#061825"
      />
      <path
        d="M13.014 26C20.1937 26 26.0281 20.1657 26.0281 12.986C26.0281 5.80632 20.1938 0 13.014 0C5.83438 0 0 5.80664 0 12.986C0 20.1656 5.83428 26 13.014 26ZM13.014 25.1454C6.30612 25.1454 0.882359 19.694 0.882359 12.9867C0.882359 6.27882 6.30622 0.855059 13.014 0.855059C19.7219 0.855059 25.1457 6.27892 25.1457 12.9867C25.1457 19.6946 19.7218 25.1454 13.014 25.1454Z"
        fill="#061825"
      />
    </svg>
  );
}
