import React from "react";

export default function BillingIcon({ size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.0031 5.10418C2.00068 5.12735 1.99967 5.15071 2.0001 5.17412V15.8158C1.99916 15.8667 2.00503 15.918 2.01806 15.9685C2.03695 16.0419 2.07006 16.1096 2.11432 16.1687C2.1593 16.2289 2.21454 16.2782 2.27617 16.3156C2.35259 16.3622 2.44075 16.3916 2.53521 16.3984C2.59073 16.4025 2.64713 16.3986 2.70262 16.3864C2.76461 16.3727 2.82285 16.3492 2.87559 16.3175C3.91388 15.7235 5.09077 15.4109 6.2887 15.4109C7.48664 15.4109 8.66354 15.7235 9.70183 16.3175C9.78886 16.3698 9.89089 16.3999 10 16.3999C10.1091 16.3999 10.2111 16.3698 10.2982 16.3175C11.3365 15.7235 12.5134 15.4109 13.7113 15.4109C14.9092 15.4109 16.0861 15.7235 17.1244 16.3175C17.1772 16.3492 17.2354 16.3727 17.2974 16.3864C17.3529 16.3986 17.4093 16.4025 17.4648 16.3984C17.5592 16.3916 17.6474 16.3622 17.7238 16.3157C17.7854 16.2783 17.8407 16.2289 17.8857 16.1687C17.93 16.1095 17.9632 16.0417 17.982 15.9681C17.995 15.9178 18.0008 15.8666 17.9999 15.8158V5.17408C18.0003 5.1508 17.9993 5.12758 17.9969 5.10454C17.9888 5.02479 17.9643 4.94985 17.9267 4.88313C17.8844 4.80764 17.8245 4.74081 17.7488 4.68936C17.734 4.67923 17.7186 4.66977 17.7028 4.66102C16.4887 3.96589 15.1123 3.59998 13.7113 3.59998C12.417 3.59998 11.1437 3.91227 10 4.50792C8.8563 3.91227 7.58301 3.59998 6.2887 3.59998C4.88769 3.59998 3.5113 3.96589 2.29723 4.66101C2.28129 4.66983 2.26581 4.67937 2.25085 4.6896C2.17533 4.74099 2.11556 4.8077 2.07333 4.88304C2.03581 4.94968 2.01127 5.02452 2.0031 5.10418ZM16.8453 5.50365C15.8774 5.00796 14.8031 4.74826 13.7113 4.74826C12.6195 4.74826 11.5452 5.00796 10.5773 5.50365V14.8985C11.5652 14.4802 12.6309 14.2626 13.7113 14.2626C14.7916 14.2626 15.8574 14.4802 16.8453 14.8985V5.50365ZM9.42269 5.50365V14.8985C8.43476 14.4802 7.36905 14.2626 6.2887 14.2626C5.20836 14.2626 4.14265 14.4802 3.15472 14.8985V5.50365C4.12257 5.00796 5.1969 4.74826 6.2887 4.74826C7.38051 4.74826 8.45484 5.00796 9.42269 5.50365Z"
        fill="#6D6F96"
      />
    </svg>
  );
}
