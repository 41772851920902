import { Box, Stack } from "@mui/material";
import React from "react";

import DashboardHeader from "./header/dashboard-header";
import SideNav from "./navigation/desktop/sideNav";

export function PageLayout({ children, showSideNav = true }) {
  return (
    <Box display="flex">
      {showSideNav && <SideNav />}
      <Stack flex="4 4">
        <DashboardHeader />
        <Stack sx={{ paddingRight: "40px", paddingLeft: "24px", paddingY: "16px" }}>
          {children}
        </Stack>
      </Stack>
    </Box>
  );
}
