import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { WrappedAuth0ProviderWithAxios } from "./Auth0ProviderWithNavigate";
import { StripeProvider } from "./StripeProvider";
import { AuthenticationGuard } from "./components/authentication-guard";
import { NoAuthenticationGuard } from "./components/no-authentication-guard";
import AuthenticatedRoutes from "./routes/AuthenticatedRoutes";
import AuthenticationRoutes from "./routes/AuthenticationRoutes";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <BrowserRouter>
          <WrappedAuth0ProviderWithAxios>
            <StripeProvider>
              <Routes>
                <Route
                  path="auth/*"
                  element={
                    <NoAuthenticationGuard>
                      <AuthenticationRoutes />
                    </NoAuthenticationGuard>
                  }
                />
                <Route
                  path="/*"
                  element={
                    <AuthenticationGuard>
                      <AuthenticatedRoutes />
                    </AuthenticationGuard>
                  }
                />
              </Routes>
            </StripeProvider>
          </WrappedAuth0ProviderWithAxios>
        </BrowserRouter>
      </header>
    </div>
  );
}

export default App;
