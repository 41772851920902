import moment from "moment-timezone";

import { moesifApiClient } from "./api-client-service";

export const getEvents = async (params, payload) => {
  const queryParams = new URLSearchParams(params).toString();
  return moesifApiClient.post(`/moesif/events?${queryParams}`, payload);
};

export const prepareEventsChartData = async (events, onPointClick) => {
  const x = events.chartData.dataSets.map((dataset) =>
    moment(new Date(dataset.label.split(" ")[0])).format("MMM")
  );
  const y = events.chartData.dataSets.map((dataset) => ({
    ...dataset,
    y: dataset.data,
  }));
  const chartOptions = {
    accessibility: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    chart: {
      type: "column",
      style: {
        fontFamily: "Open Sans",
        fontSize: "14px",
      },
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: x,
      labels: {
        style: {
          fontSize: "14px",
          color: "#BDBDBD",
        },
      },
    },
    yAxis: {
      title: {
        text: "",
      },
      labels: {
        style: {
          fontSize: "14px",
          color: "#BDBDBD",
        },
      },
    },
    series: [
      {
        name: "Usage",
        data: y,
        color: {
          linearGradient: {
            x1: 0,
            x2: 0,
          },
          stops: [
            [0, "#5567EA"],
            [0.97, "#8A80FF"],
          ],
        },
        events: {
          click(event) {
            onPointClick(event.point.options);
          },
        },
      },
    ],
    legend: {
      align: "right",
      symbolRadius: 0,
      symbolHeight: 8,
      symbolWidth: 21,
      squareSymbol: false,
    },
  };
  return { x, y, chartOptions };
};

export const getTableData = (params) => {
  const queryParams = new URLSearchParams(params).toString();
  return moesifApiClient.get(`/moesif/table-data?${queryParams}`);
};

export const downloadTableData = (params) => {
  const queryParams = new URLSearchParams(params).toString();
  return moesifApiClient.get(`/moesif/download-table-data?${queryParams}`, {
    responseType: "blob",
  });
};
