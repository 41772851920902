import { Box, Stack, Typography } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { getEvents, prepareEventsChartData } from "../../services/moesif-service";
import ButtonOutlineSecodary from "../../styled-components/button-outline-secodary";

export default function MoesifChart() {
  const [moesifEventsData, setMoesifEventsData] = useState();
  const [eventsChartData, setEventsChartData] = useState();
  const [intervalCount, setIntervalCount] = useState(1);
  const [interval, setInterval] = useState("MONTH");
  const [period, setPeriod] = useState(12);
  const [moesifPayload, setMoesifPayload] = useState({
    interval: "MONTH",
    interval_count: 1,
    time_zone: moment.tz.guess(),
  });
  const [responseTime, setResponseTime] = useState();
  const [errorRates, setErrorRates] = useState({
    overAll: {
      count: 0,
      rate: 0,
    },
    400: {
      count: 0,
      rate: 0,
    },
    500: {
      count: 0,
      rate: 0,
    },
    401: {
      count: 0,
      rate: 0,
    },
  });
  const [topEndpoints, setTopEndpoints] = useState([]);

  const [difference, setdifference] = useState();
  const [differenceInPercentage, setdifferenceInPercentage] = useState();

  const onPointClick = (data) => {
    setErrorRates(data.errorRates);
    setResponseTime(data.averageResponseTime);
    setTopEndpoints(data.topEndpoints);
  };

  const getApiCallDeference = () => {
    if (moesifEventsData.chartData.dataSets.length < 2) {
      setdifference(moesifEventsData.total);
      setdifferenceInPercentage(100);
      return;
    }
    const datasetLength = moesifEventsData.chartData.dataSets.length;
    const diffInNumbers =
      moesifEventsData.chartData.dataSets[datasetLength - 1].data -
      moesifEventsData.chartData.dataSets[datasetLength - 2].data;
    const diffInPercentage =
      diffInNumbers === moesifEventsData.chartData.dataSets[datasetLength - 1].data
        ? 100
        : (diffInNumbers / moesifEventsData.chartData.dataSets[datasetLength - 2].data) * 100;
    setdifference(diffInNumbers);
    setdifferenceInPercentage(diffInPercentage);
  };

  const loadChart = async (events) => {
    const chartData = await prepareEventsChartData(events, onPointClick);
    setEventsChartData(chartData.chartOptions);
    onPointClick(events.chartData.dataSets[events.chartData.dataSets.length - 1]);
  };

  const getMoesifEvents = async (payload) => {
    try {
      const events = await getEvents({}, payload);
      setMoesifEventsData(events.data);
    } catch (error) {
      console.error("Error fetching Moesif events", error);
    }
  };

  useEffect(() => {
    const from = moment()
      .subtract(period - 1, "month")
      .startOf("month")
      .format("MM/DD/YYYY");
    const to = moment().endOf("month").format("MM/DD/YYYY");
    setMoesifPayload({
      ...moesifPayload,
      from,
      to,
    });
    setIntervalCount(intervalCount);
    setInterval(interval);
  }, [period]);

  useEffect(() => {
    if (moesifEventsData) {
      loadChart(moesifEventsData);
      getApiCallDeference();
    }
  }, [moesifEventsData]);

  useEffect(() => {
    getMoesifEvents(moesifPayload);
  }, [moesifPayload]);

  return (
    <>
      <Typography fontWeight={600} fontSize={22} color="#272950" fontFamily="Raleway">
        Dashboard
      </Typography>
      <Stack flexDirection="row" mt={4} gap="21px">
        <Stack gap="25px" sx={{ width: "260px" }} flexDirection="column">
          <Stack
            gap="10px"
            sx={{
              width: "100%",
              height: "92px",
              p: "15px",
              border: "1px solid #E2E2E8",
              borderRadius: "5px",
            }}
          >
            <Typography
              fontWeight={500}
              fontSize="18px"
              lineHeight="28px"
              color="#6D6F96"
              fontFamily="Open Sans"
            >
              Response Time
            </Typography>
            <Typography
              fontWeight={600}
              fontSize="22px"
              lineHeight="24px"
              color="#272950"
              fontFamily="Open Sans"
            >
              {responseTime ?? 0} seconds
            </Typography>
          </Stack>
          <Stack
            gap="10px"
            sx={{
              width: "100%",
              p: "15px",
              border: "1px solid #E2E2E8",
              borderRadius: "5px",
            }}
          >
            <Typography
              fontWeight={500}
              fontSize="18px"
              lineHeight="28px"
              color="#6D6F96"
              fontFamily="Open Sans"
            >
              Error Rates
            </Typography>
            <Typography
              fontWeight={700}
              fontSize="14px"
              lineHeight="28px"
              color="#E04369"
              fontFamily="Open Sans"
            >
              {`Overall: `}
              <span style={{ color: "#272950", fontWeight: "400" }}>
                {errorRates.overAll.rate ?? 0}%
              </span>
            </Typography>
            <Typography
              fontWeight={700}
              fontSize="14px"
              lineHeight="28px"
              color="#272950"
              fontFamily="Open Sans"
            >
              {`400 Bad Request: `}
              <span style={{ color: "#272950", fontWeight: "400" }}>
                {errorRates["400"].rate ?? 0}%
              </span>
            </Typography>
            <Typography
              fontWeight={700}
              fontSize="14px"
              lineHeight="28px"
              color="#272950"
              fontFamily="Open Sans"
            >
              {`401 Unauthorized: `}
              <span style={{ color: "#272950", fontWeight: "400" }}>
                {errorRates["401"].rate ?? 0}%
              </span>
            </Typography>
            <Typography
              fontWeight={700}
              fontSize="14px"
              lineHeight="28px"
              color="#272950"
              fontFamily="Open Sans"
            >
              {`500 Internal Server Error: `}
              <span style={{ color: "#272950", fontWeight: "400" }}>
                {errorRates["500"].rate ?? 0}%
              </span>
            </Typography>
          </Stack>
          <Stack
            gap="10px"
            sx={{
              width: "100%",
              p: "15px",
              border: "1px solid #E2E2E8",
              borderRadius: "5px",
            }}
          >
            <Typography
              fontWeight={500}
              fontSize="18px"
              lineHeight="28px"
              color="#6D6F96"
              fontFamily="Open Sans"
            >
              Top Endpoints
            </Typography>
            {topEndpoints?.map((endpoint) => (
              <Typography
                fontWeight={400}
                fontSize="14px"
                lineHeight="28px"
                color="#272950"
                fontFamily="Open Sans"
                key={endpoint}
                sx={{ wordBreak: "break-all" }}
              >
                <Link style={{ color: "#272950" }} to={endpoint}>
                  {endpoint}
                </Link>
              </Typography>
            ))}
          </Stack>
        </Stack>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            flex: "3 3",
            border: "1px solid #E2E2E8",
            borderRadius: "5px",
            p: "16px",
          }}
        >
          <Stack flexDirection="row" justifyContent="space-between" sx={{ pb: "10px" }}>
            <Stack flexDirection="column" gap="16px">
              <Typography
                fontWeight={500}
                fontSize="18px"
                lineHeight="28px"
                color="#6D6F96"
                fontFamily="Raleway"
              >
                API Volume Usage
              </Typography>
              <Typography
                fontWeight={600}
                fontSize="22px"
                lineHeight="24px"
                color="#272950"
                fontFamily="Raleway"
              >
                {moesifEventsData?.total ?? 0} Calls
              </Typography>
              {typeof difference !== "undefined" && (
                <div style={{ display: "flex" }}>
                  <Typography
                    fontWeight={500}
                    fontSize="14px"
                    lineHeight="20px"
                    color={(() => {
                      if (difference < 0) return "#E04369";
                      if (difference > 0) return "#108F65";
                      return "#272950";
                    })()}
                    mb="15px"
                    fontFamily="Open Sans"
                  >
                    {`${difference} (${differenceInPercentage?.toFixed(5)}%) `}
                    <span style={{ color: "#272950" }}>1m change</span>
                  </Typography>
                </div>
              )}
            </Stack>
            <Stack flexDirection="column-reverse" gap="16px">
              <Stack
                flexDirection="row"
                gap="16px"
                sx={{
                  fontFamily: "Open Sans",
                  fontSize: "12px",
                  lineHeight: "16px",
                }}
              >
                <ButtonOutlineSecodary
                  className={period === 1 ? "active" : ""}
                  onClick={() => setPeriod(1)}
                  sx={{ fontSize: "12px" }}
                >
                  1m
                </ButtonOutlineSecodary>
                <ButtonOutlineSecodary
                  className={period === 3 ? "active" : ""}
                  variant="outlined"
                  onClick={() => setPeriod(3)}
                  sx={{ fontSize: "12px" }}
                >
                  3m
                </ButtonOutlineSecodary>
                <ButtonOutlineSecodary
                  className={period === 6 ? "active" : ""}
                  variant="outlined"
                  onClick={() => setPeriod(6)}
                  sx={{ fontSize: "12px" }}
                >
                  6m
                </ButtonOutlineSecodary>
                <ButtonOutlineSecodary
                  className={period === 12 ? "active" : ""}
                  variant="outlined"
                  onClick={() => setPeriod(12)}
                  sx={{ fontSize: "12px" }}
                >
                  12m
                </ButtonOutlineSecodary>
              </Stack>
            </Stack>
          </Stack>
          <Box display="flex" gap="20px" sx={{ flexDirection: "column", alignItems: "center" }}>
            <Stack spacing={2} sx={{ width: "100%" }}>
              {eventsChartData && (
                <HighchartsReact highcharts={Highcharts} options={eventsChartData} />
              )}
            </Stack>
          </Box>
        </Box>
      </Stack>
    </>
  );
}
