import React from "react";

export default function KeyIcon({ width, height }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1227 0.000659124C9.79791 0.031072 9.50654 0.209634 9.33093 0.484345L0.891467 13.4073C0.648157 13.7654 0.648157 14.2353 0.891467 14.5934L9.33093 27.5163C9.5242 27.8155 9.85482 27.998 10.21 28H27.0897C27.4448 27.998 27.7755 27.8156 27.9687 27.5163L36.0568 15.0771H75.2226H75.2216C75.5042 15.0811 75.776 14.9722 75.9771 14.774C76.1782 14.5758 76.291 14.305 76.291 14.0225C76.291 13.7399 76.1782 13.4691 75.9771 13.271C75.776 13.0728 75.5042 12.9639 75.2216 12.9678H36.101L27.9687 0.484418H27.9697C27.7765 0.185186 27.4458 0.00367434 27.0907 0.000735772H10.211C10.1816 -0.000245257 10.1531 -0.000245257 10.1237 0.000735772L10.1227 0.000659124ZM10.7819 2.11098H26.5176L34.2104 14.0235L26.5176 25.8918H10.7819L3.08919 14.0235L10.7819 2.11098Z"
        fill="#15E5A7"
      />
      <path
        d="M18.6821 8.14075C15.4072 8.14075 12.6995 10.7624 12.6995 14.0166C12.6995 17.2699 15.4062 19.9601 18.6821 19.9601C21.9569 19.9601 24.5961 17.2709 24.5961 14.0166C24.5961 10.7634 21.9569 8.14075 18.6821 8.14075ZM18.6821 10.2344C20.813 10.2344 22.4887 11.8993 22.4887 14.0166C22.4887 16.1338 20.8129 17.8654 18.6821 17.8654C16.5512 17.8654 14.8067 16.1337 14.8067 14.0166C14.8067 11.8994 16.5501 10.2344 18.6821 10.2344Z"
        fill="#15E5A7"
      />
    </svg>
  );
}
