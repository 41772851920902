import React from "react";

export default function APIKeyIcon({ size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.423 10C18.423 11.0128 18.2241 11.9945 17.8336 12.9197C17.4559 13.8139 16.9157 14.615 16.2259 15.3029C15.538 15.9909 14.7351 16.5328 13.8427 16.9106C12.9175 17.3029 11.9358 17.5 10.923 17.5C9.72597 17.5 8.58181 17.2263 7.52352 16.688C6.51441 16.1752 5.61841 15.4234 4.9341 14.5183L6.09834 13.6387C7.2516 15.1643 9.00892 16.0402 10.9213 16.0402C14.2516 16.0402 16.9616 13.3303 16.9616 9.99993C16.9616 6.66952 14.2518 3.95634 10.9213 3.95634C8.22975 3.95634 5.94318 5.73552 5.16604 8.17338L6.04195 7.42156L6.99267 8.52921L4.07481 11.0365L1.57666 8.11682L2.68614 7.1679L3.62592 8.26644C3.72081 7.86315 3.85037 7.46718 4.01278 7.08395C4.39051 6.18979 4.93067 5.38869 5.61861 4.69891C6.30656 4.00913 7.10949 3.469 8.00181 3.09125C8.92701 2.69891 9.90872 2.5 10.9215 2.5C11.9343 2.5 12.916 2.6989 13.8412 3.08941C14.7354 3.46714 15.5364 4.00729 16.2244 4.69707C16.9123 5.38502 17.4543 6.18795 17.8321 7.08028C18.2244 8.00547 18.4233 8.98718 18.4233 9.99997L18.423 10Z"
        fill="#6D6F96"
      />
      <path
        d="M13.4595 13.5638L10.2041 10.301V6.11304H11.6639V9.69875L14.4924 12.5327L13.4595 13.5638Z"
        fill="#6D6F96"
      />
    </svg>
  );
}
