import ClearIcon from "@mui/icons-material/Clear";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import { Box, Button, Divider, OutlinedInput, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactInputMask from "react-input-mask";

export default function PaymentMethodModal({ handleClose }) {
  const [cardDetails, setCardDetails] = useState({
    cardNumber: "",
    nameOnCard: "",
    expiryDate: "",
    securityCode: "",
    saved: false,
  });

  useEffect(() => {
    setCardDetails({
      cardNumber: "",
      nameOnCard: "",
      expiryDate: "",
      securityCode: "",
      saved: false,
    });
  }, []);

  const handleAPI = async () => {
    console.log(`\n  cardDetails:`, cardDetails);
    // API call to save card details
  };

  return (
    <>
      <Box>
        <Box display="flex" width="100%" alignItems="center" justifyContent="space-between">
          <Typography
            id="keep-mounted-modal-title"
            variant="h6"
            component="h2"
            fontWeight={500}
            fontSize="18px"
            lineHeight="28px"
            color="#575978"
          >
            Add Payment Method
          </Typography>
          <ClearIcon
            onClick={() => handleClose()}
            sx={{ color: "#272950", cursor: "pointer" }}
            fontSize="medium"
          />
        </Box>
        <Divider sx={{ my: "16px" }} />
        {cardDetails.saved ? (
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            gap="12px"
          >
            <Typography
              id="keep-mounted-modal-description"
              fontWeight={500}
              fontSize="20px"
              color="#6D6F96"
              lineHeight="28px"
            >
              Complete
            </Typography>
            <Typography
              id="keep-mounted-modal-description"
              fontWeight={400}
              fontSize="12px"
              color="#6D6F96"
              lineHeight="20px"
            >
              Your new payment method has been successfully saved
            </Typography>
            <DoneRoundedIcon sx={{ fontSize: "76px", color: "#15E5A7", fontWeight: "800" }} />
          </Box>
        ) : (
          <Stack gap="22px">
            <Box>
              <Typography
                id="keep-mounted-modal-description"
                fontWeight={400}
                fontSize="14px"
                color="#8d8fab"
                lineHeight="20px"
              >
                Card Number
              </Typography>
              <ReactInputMask
                mask="9999 9999 9999 9999"
                value={cardDetails.cardNumber}
                disabled={false}
                maskChar={null}
                onChange={(e) => {
                  setCardDetails({ ...cardDetails, cardNumber: e.target.value, saved: false });
                }}
              >
                {() => (
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type="text"
                    placeholder="4111 1111 1111 1111"
                    sx={{
                      fontSize: "14px",
                      height: "36px",
                      width: "100%",
                      mt: "10px",
                      lineHeight: "22px",
                      fontWeight: 400,
                    }}
                  />
                )}
              </ReactInputMask>
            </Box>
            <Box>
              <Typography
                id="keep-mounted-modal-description"
                fontWeight={400}
                fontSize="14px"
                color="#8d8fab"
                lineHeight="20px"
              >
                Name On Card
              </Typography>
              <OutlinedInput
                id="outlined-adornment-password"
                type="text"
                placeholder="John Doe"
                sx={{
                  fontSize: "14px",
                  height: "36px",
                  width: "100%",
                  mt: "10px",
                  lineHeight: "22px",
                  fontWeight: 400,
                }}
                value={cardDetails.nameOnCard}
                onChange={(e) => {
                  setCardDetails({ ...cardDetails, nameOnCard: e.target.value, saved: false });
                }}
              />
            </Box>
            <Stack direction="row" gap="22px">
              <Box>
                <Typography
                  id="keep-mounted-modal-description"
                  fontWeight={400}
                  fontSize="14px"
                  color="#8d8fab"
                  lineHeight="20px"
                >
                  Expiration date
                </Typography>
                <ReactInputMask
                  mask="99/9999"
                  value={cardDetails.expiryDate}
                  maskChar={null}
                  disabled={false}
                  alwaysShowMask={false}
                  onChange={(e) => {
                    setCardDetails({ ...cardDetails, expiryDate: e.target.value, saved: false });
                  }}
                >
                  {() => (
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type="text"
                      placeholder="MM/YYYY"
                      sx={{
                        fontSize: "14px",
                        height: "36px",
                        width: "100%",
                        mt: "10px",
                        lineHeight: "22px",
                        fontWeight: 400,
                      }}
                    />
                  )}
                </ReactInputMask>
              </Box>
              <Box>
                <Typography
                  id="keep-mounted-modal-description"
                  fontWeight={400}
                  fontSize="14px"
                  color="#8d8fab"
                  lineHeight="20px"
                >
                  Security code
                </Typography>
                <ReactInputMask
                  mask="999"
                  value={cardDetails.securityCode}
                  maskChar={null}
                  disabled={false}
                  alwaysShowMask={false}
                  onChange={(e) => {
                    setCardDetails({ ...cardDetails, securityCode: e.target.value, saved: false });
                  }}
                >
                  {() => (
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type="text"
                      placeholder="123"
                      sx={{
                        fontSize: "14px",
                        height: "36px",
                        width: "100%",
                        mt: "10px",
                        lineHeight: "22px",
                        fontWeight: 400,
                      }}
                    />
                  )}
                </ReactInputMask>
              </Box>
            </Stack>
            <Typography fontSize="12px" fontWeight={400} lineHeight="16px" color="#272950">
              Your card will not be charged instantly. Instead, your subscription fee will be
              processed on the inaugural day of each billing cycle, utilizing the payment method
              you&apos;ve detailed above. Real Yeti will securely store your payment details. <br />
              <br />
              Your billing statement will show Entity Xyz., based in Arizona, United States of
              America. <br />
              <br />
              Please note that your credit card provider may apply additional charges such as
              foreign transaction or cross-border fees to the total price.
            </Typography>
          </Stack>
        )}
      </Box>
      {cardDetails.saved ? (
        <Button
          onClick={() => handleClose(true)}
          variant="outlined"
          sx={{
            width: "100%",
            border: "1px solid #061825",
            color: "#061825",
            fontSize: "12px",
            fontWeight: 400,
            "&:hover": { border: "1px solid #061825" },
          }}
        >
          Close
        </Button>
      ) : (
        <Button
          onClick={handleAPI}
          variant="outlined"
          sx={{
            width: "100%",
            border: "1px solid #061825",
            color: "#061825",
            fontSize: "12px",
            fontWeight: 400,
            "&:hover": { border: "1px solid #061825" },
          }}
        >
          Save
        </Button>
      )}
    </>
  );
}
