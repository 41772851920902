import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@mui/material";
import React from "react";

function LoginButtonWithAuth0() {
  const { loginWithRedirect } = useAuth0();
  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/dashboard",
      },
      authorizationParams: {
        prompt: "login",
      },
      scope: "openid profile email offline_access",
    });
  };

  return (
    <Button
      sx={{
        width: "80px",
        height: "35px",
        color: "#272950",
        fontWeight: "500",
        fontSize: "12px",
        border: "1px solid #E2E2E8",
        borderRadius: "5px",
      }}
      onClick={handleLogin}
    >
      LogIn
    </Button>
  );
}

export function LoginButton() {
  return <LoginButtonWithAuth0 />;
}
