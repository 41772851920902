import { useAuth0 } from "@auth0/auth0-react";
import { Stack, Box, Typography } from "@mui/material";
import React from "react";

import vector from "../../assets/icons/Vector.png";
import { PageLayout } from "../page-layout";
import { PageLoader } from "../page-loader";
import "../../styles/components/layouts/page-layout.css";

export default function Auth0StripeProducts() {
  const { user, isLoading } = useAuth0();

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <PageLayout showSideNav={false}>
      <Stack p={5} gap={4}>
        <Box display="flex" gap={5} alignItems="center">
          <img src={vector} width={30} height={30} alt="logo" />
          <Typography variant="h2" color="#000">
            Select Plan
          </Typography>
        </Box>
        <Typography variant="h3" color="#000">
          Select a payment plan to finish setting up your account.
        </Typography>
        <Box p={10} display="flex" justifyContent="center">
          <stripe-pricing-table
            pricing-table-id={process.env.REACT_APP_STRIPE_PRICING_TABLE_ID}
            publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
            customer-email={user.email}
            style={{
              width: "100%",
              maxWidth: "1000px",
              backgroundColor: "#fff",
            }}
          />
        </Box>
      </Stack>
    </PageLayout>
  );
}
