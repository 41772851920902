import { useAuth0 } from "@auth0/auth0-react";
import styled from "@emotion/styled";
import { Stack, Box, List, ListItemButton, ListItemText, Typography, Button } from "@mui/material";
import React, { useState, useEffect } from "react";

import APIKeyIcon from "../../../assets/icons/apiKey";
import BillingIcon from "../../../assets/icons/billing";
import DashboardIcon from "../../../assets/icons/dashboard";
import realyetiWordmarkIcon from "../../../images/icons/realyeti-wordmark.svg";

function SideNav() {
  const [currentTab, setCurrentTab] = useState();
  const { user: auth0User } = useAuth0();

  useEffect(() => {
    const href = window.location.href.split("/");
    setCurrentTab(href[href.length - 1]);
  }, [auth0User]);

  return (
    <Stack pl={2} flex="1 1" borderRight="1px solid" minHeight="100vh" maxWidth="264px">
      <Box height={64} display="flex" alignItems="center" borderBottom="1px solid">
        <img
          src={realyetiWordmarkIcon}
          width={119}
          height={26}
          style={{ marginInline: 16 }}
          alt="logo"
        />
      </Box>
      <Box p={2} display="flex" flexDirection="column" justifyContent="space-between" height="80%">
        <List sx={{}}>
          <ListButton component="a" href="/dashboard" sx={{ borderRadius: "5px", gap: "5px" }}>
            <DashboardIcon size={16} />
            <ListItemText
              primary={
                <ListText sx={{ fontWeight: currentTab === "dashboard" ? "bold" : "none" }}>
                  Dashboard
                </ListText>
              }
            />
          </ListButton>
          <ListButton component="a" href="/keys" sx={{ borderRadius: "5px", gap: "5px" }}>
            <APIKeyIcon size={20} />
            <ListItemText
              primary={
                <ListText sx={{ fontWeight: currentTab === "keys" ? "bold" : "none" }}>
                  API Keys
                </ListText>
              }
            />
          </ListButton>
          <ListButton component="a" href="/billing" sx={{ borderRadius: "5px", gap: "5px" }}>
            <BillingIcon size={20} />
            <ListItemText
              primary={
                <ListText sx={{ fontWeight: currentTab === "billing" ? "bold" : "none" }}>
                  Billing
                </ListText>
              }
            />
          </ListButton>
        </List>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            background: "#F5F6FD",
            borderRadius: "4px",
            padding: "26px 30px 26px 26px",
            height: "168px",
            justifyContent: "space-between",
          }}
        >
          <Typography fontSize={12} fontWeight={500} color="#272950">
            Support
          </Typography>
          <Typography fontSize={12} fontWeight={400} color="#575983">
            Please contact us for support
          </Typography>
          <Button
            variant="outlined"
            sx={{
              width: "120px",
              height: "32px",
              border: "1px solid #061825",
              color: "#061825",
              "&:hover": { border: "1px solid #061825" },
            }}
            onClick={() => window.open("https://www.info.realyeti.xyz/contact", "_blank")}
          >
            Contact Us
          </Button>
        </Box>
      </Box>
    </Stack>
  );
}

const ListButton = styled(ListItemButton)({
  color: "#6D6F96",
  "&:hover": {
    background: "#F5F9FF",
  },
});

const ListText = styled(Typography)({
  fontSize: "14px",
  fontFamily: "Open Sans",
});

export default SideNav;
