import { Stack, Typography } from "@mui/material";
import React from "react";

import PaymentMethods from "./payment-methods";
import { PageLayout } from "../../page-layout";
import PlanDetails from "../../plan-details/PlanDetaills";

export default function BillingPage() {
  return (
    <PageLayout>
      <Typography
        fontWeight={600}
        fontSize="22px"
        color="#272950"
        fontFamily="Raleway"
        lineHeight="24px"
      >
        Billing
      </Typography>
      <Typography
        fontWeight={400}
        fontSize="14px"
        color="#272950"
        fontFamily="Open Sans"
        lineHeight="20px"
        sx={{ marginTop: "32px", maxWidth: "631px" }}
      >
        Our flexible subscription plans are designed to grow with you. Upgrade or downgrade your
        plan at any time to match your changing needs, ensuring you always have the right level of
        service without any hassle.
      </Typography>
      <Stack marginTop="32px">
        <PlanDetails />
      </Stack>
      <Stack marginTop="32px">
        <PaymentMethods />
      </Stack>
    </PageLayout>
  );
}
