import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Navigate } from "react-router-dom";

export function NoAuthenticationGuard({ children }) {
  const { isAuthenticated } = useAuth0();

  if (!isAuthenticated) {
    return children;
  }

  return <Navigate to="/dashboard" replace />;
}
