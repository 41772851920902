import { useAuth0 } from "@auth0/auth0-react";
import ClearIcon from "@mui/icons-material/Clear";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import { Box, Button, Divider, OutlinedInput, Typography } from "@mui/material";
import React, { useRef, useState, useEffect } from "react";

import { createAPIKey } from "../../../services/keys-service";

export default function ModalCreate({ handleClose }) {
  const { user } = useAuth0();
  const [apiKey, setApiKey] = useState({
    text: "",
    saved: false,
  });
  const inputRef = useRef(null);
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    setApiKey({ text: "", saved: false });
  }, []);

  const handleAPI = async () => {
    try {
      const { email } = user;
      await createAPIKey({ email, name: apiKey.text });

      setApiKey((old) => ({ ...old, saved: true }));
    } catch (error) {
      if (error?.response?.data?.error_message) {
        handleClose(false, true);
      }
      console.error("error", error.response);
    }
  };

  return (
    <>
      <Box>
        <Box display="flex" width="100%" alignItems="center" justifyContent="space-between">
          <Typography
            id="keep-mounted-modal-title"
            variant="h6"
            component="h2"
            fontWeight={500}
            fontSize="18px"
            lineHeight="28px"
            color="#6D6F96"
          >
            Create API Key
          </Typography>
          <ClearIcon
            onClick={() => handleClose()}
            sx={{ color: "#272950", cursor: "pointer" }}
            fontSize="medium"
          />
        </Box>
        <Divider sx={{ my: "16px" }} />
        {apiKey.saved ? (
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            gap="12px"
          >
            <Typography
              id="keep-mounted-modal-description"
              fontWeight={500}
              fontSize="20px"
              color="#6D6F96"
              lineHeight="28px"
            >
              Complete
            </Typography>
            <Typography
              id="keep-mounted-modal-description"
              fontWeight={400}
              fontSize="12px"
              color="#6D6F96"
              lineHeight="20px"
            >
              Your new API Key has been successfully created
            </Typography>
            <DoneRoundedIcon sx={{ fontSize: "76px", color: "#15E5A7", fontWeight: "800" }} />
          </Box>
        ) : (
          <Box>
            <Typography
              id="keep-mounted-modal-description"
              fontWeight={400}
              fontSize="14px"
              color="#A1A2BD"
              lineHeight="20px"
            >
              Name
            </Typography>
            <OutlinedInput
              id="outlined-adornment-password"
              type="text"
              placeholder="e.g. Private Credit"
              sx={{
                fontSize: "14px",
                height: "36px",
                width: "100%",
                mt: "10px",
              }}
              value={apiKey.text}
              onChange={(e) => {
                setApiKey({ text: e.target.value, saved: false });
              }}
              inputRef={inputRef}
            />
          </Box>
        )}
      </Box>
      {apiKey.saved ? (
        <Button
          onClick={() => handleClose(true)}
          variant="outlined"
          sx={{
            width: "100%",
            border: "1px solid #061825",
            color: "#061825",
            fontSize: "12px",
            fontWeight: 400,
            "&:hover": { border: "1px solid #061825" },
          }}
        >
          Close
        </Button>
      ) : (
        <Button
          onClick={handleAPI}
          variant="outlined"
          sx={{
            width: "100%",
            border: "1px solid #061825",
            color: "#061825",
            fontSize: "12px",
            fontWeight: 400,
            "&:hover": { border: "1px solid #061825" },
          }}
        >
          Create New API Key
        </Button>
      )}
    </>
  );
}
