import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";

import { ApiClientProvider } from "./ApiClientContext";
import { apiClient, moesifApiClient } from "./services/api-client-service";
import { subscribeToFreePlan } from "./services/stripe-service";

export default function withAxiosInterceptors(WrappedComponent) {
  return function (props) {
    const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

    useEffect(() => {
      const apiClientInterceptor = apiClient.interceptors.response.use(
        (response) => response,
        async (error) => {
          const originalRequest = error.config;
          if (error.response && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
              const accessToken = await getAccessTokenSilently();
              apiClient.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
              originalRequest.headers.Authorization = `Bearer ${accessToken}`;
              return apiClient(originalRequest);
            } catch (err) {
              console.error("Error refreshing token:", err);
              loginWithRedirect();
            }
          }
          if (
            error.response &&
            error.response.status === 404 &&
            error.config.url.split("?")[0] === "/stripe/customer" &&
            error.config.method === "get"
          ) {
            return subscribeToFreePlan(error.config.url.split("?")[1].split("=")[1]);
          }
          return Promise.reject(error);
        }
      );
      const moesifApiClientInterceptor = moesifApiClient.interceptors.response.use(
        (response) => response,
        async (error) => {
          const originalRequest = error.config;
          if (error.response && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
              const accessToken = await getAccessTokenSilently();
              moesifApiClient.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
              originalRequest.headers.Authorization = `Bearer ${accessToken}`;
              return moesifApiClient(originalRequest);
            } catch (err) {
              console.error("Error refreshing token:", err);
              loginWithRedirect();
            }
          }
          return Promise.reject(error);
        }
      );

      return () => {
        apiClient.interceptors.response.eject(apiClientInterceptor);
        moesifApiClient.interceptors.response.eject(moesifApiClientInterceptor);
      };
    }, [getAccessTokenSilently, loginWithRedirect]);

    return (
      <ApiClientProvider>
        <WrappedComponent {...props} />
      </ApiClientProvider>
    );
  };
}
