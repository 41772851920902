import React from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import BillingPage from "../components/pages/billing/billing-page";
import Dashboard from "../components/pages/dashboard/Dashboard";
import Keys from "../components/pages/keys/Keys";
import { PaymentGuard } from "../components/payment-guard";
import Auth0StripeProducts from "../components/stripe/Auth0StripeProducts";

export default function AuthenticatedRoutes() {
  return (
    <Routes>
      <Route path="" element={<Outlet />}>
        <Route path="" element={<PaymentGuard />}>
          <Route path="" element={<Navigate to="/dashboard" />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="keys" element={<Keys />} />
          <Route path="billing" element={<BillingPage />} />
        </Route>
        <Route path="Pricing" element={<Auth0StripeProducts />} />
      </Route>
    </Routes>
  );
}
