import { useAuth0 } from "@auth0/auth0-react";
import { Box, Stack, Typography } from "@mui/material";
import React from "react";

import realyetiWordmarkIcon from "../../../images/icons/realyeti-wordmark.svg"
import { LoginButton } from "../../buttons/login-button";
import { PageLoader } from "../../page-loader";

function Auth0Login() {
  const { isLoading, loginWithRedirect } = useAuth0();

  const handleSignUp = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/pricing",
      },
      authorizationParams: {
        prompt: "login",
        screen_hint: "signup",
      },
      scope: "openid profile email offline_access",
    });
  };

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <Stack width="100vw" height="100vh" alignItems="center" justifyContent="center">
      <Stack gap="20px" alignItems="center">
        <Box height={64} display="flex" alignItems="center">
          <img src={realyetiWordmarkIcon} width={151} height={31} style={{ marginInline: "6px" }} alt="logo" />
        </Box>
        <Typography color="#0A0D13" fontSize="16px" fontWeight="400" style={{ marginTop: "-20px" }}>
              Developer Portal
        </Typography>
        <LoginButton />
        <button
          type="button"
          style={{
            color: "#272950",
            backgroundColor: "transparent",
            outline: "none",
            border: "none",
            userSelect: "none",
            cursor: "pointer",
            lineHeight: "20px",
            fontWeight: 600,
            padding: "8px",
            "&:hover": { backgroundColor: "#272950", color: "#fff" },
            "&.active": { backgroundColor: "#272950", color: "#fff" },
          }}
          onClick={handleSignUp}
        >
          Create Account
        </button>
      </Stack>
    </Stack>
  );
}

export default Auth0Login;
