import ClearIcon from "@mui/icons-material/Clear";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import ErrorIcon from "@mui/icons-material/Error";
import { Box, Button, Divider, Typography } from "@mui/material";
import React, { useState } from "react";

import { deleteAPIKey } from "../../../services/keys-service";

export default function ModalDelete({ apiKey, handleClose }) {
  const [isDeleted, setIsDeleted] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleDelete = async () => {
    setLoading(true);
    try {
      await deleteAPIKey(apiKey.apikey);
      setIsDeleted(true);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box>
        <Box display="flex" width="100%" alignItems="center" justifyContent="space-between">
          <Typography
            id="keep-mounted-modal-title"
            variant="h6"
            component="h2"
            fontWeight={500}
            fontSize="18px"
            lineHeight="28px"
            color="#6D6F96"
          >
            Delete API Key
          </Typography>
          <ClearIcon
            onClick={() => handleClose(false)}
            sx={{ color: "#272950", cursor: "pointer" }}
            fontSize="medium"
          />
        </Box>
        <Divider sx={{ my: "16px" }} />
        <Box display="flex" flexDirection="column" gap="16px" height="100%" alignItems="center">
          {isDeleted ? (
            <Box
              display="flex"
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
              gap="12px"
            >
              <Typography
                id="keep-mounted-modal-description"
                fontWeight={500}
                fontSize="20px"
                color="#6D6F96"
                lineHeight="28px"
              >
                Complete
              </Typography>
              <Typography
                id="keep-mounted-modal-description"
                fontWeight={400}
                fontSize="12px"
                color="#6D6F96"
                lineHeight="20px"
              >
                Your API Key has been successfully deleted.
              </Typography>
              <DoneRoundedIcon sx={{ fontSize: "76px", color: "#15E5A7", fontWeight: "800" }} />
            </Box>
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              mt="15%"
              gap="20px"
            >
              <ErrorIcon sx={{ color: "#F76E65", width: "30px", height: "30px" }} />
              <Typography fontWeight={400} fontSize="12px" color="#6D6F96">
                Are you sure you want to delete this API Key?
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      {isDeleted ? (
        <Button
          onClick={() => handleClose(true)}
          variant="outlined"
          sx={{
            width: "100%",
            border: "1px solid #061825",
            color: "#061825",
            fontSize: "12px",
            fontWeight: 400,
            "&:hover": { border: "1px solid #061825" },
          }}
        >
          Close
        </Button>
      ) : (
        <Button
          onClick={handleDelete}
          variant="outlined"
          sx={{
            width: "100%",
            border: "1px solid #061825",
            color: "#061825",
            fontSize: "12px",
            fontWeight: 400,
            "&:hover": { border: "1px solid #061825" },
          }}
          loading={loading}
        >
          Delete API Key
        </Button>
      )}
    </>
  );
}
