import { Button } from "@mui/material";
import { styled } from "@mui/system";

const ButtonOutlineSecodary = styled(Button)((props) => ({
  color: "#272950",
  backgroundColor: "transparent",
  border: "1px solid #E2E2E8",
  outline: "none",
  userSelect: "none",
  cursor: "pointer",
  borderRadius: "5px",
  fontSize: props.fontSize,
  lineHeight: props.lineHeight,
  fontFamily: props.fontFamily,
  textTransform: "capitalize",
  padding: "8px",
  "&:hover": {
    backgroundColor: "#272950 !important",
    color: "#fff !important",
  },
  "&.active": {
    backgroundColor: "#272950 !important",
    color: "#fff !important",
  },
}));

export default ButtonOutlineSecodary;
