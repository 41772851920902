import styled from "@emotion/styled";
import {
  Stack,
  Box,
  Typography,
  Button,
  Modal,
  Divider,
  Table,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
} from "@mui/material";
import moment from "moment-timezone";
import React, { useState, useEffect } from "react";
import { FiPlus, FiEdit } from "react-icons/fi";
import { RiDeleteBinFill } from "react-icons/ri";

import ModalCreate from "./CreateModal";
import ModalDelete from "./DeleteModal";
import ModalEdit from "./EditModal";
import ModalWarning from "./WarningModal";
import KeyIcon from "../../../assets/icons/key";
import { getAPIKeys } from "../../../services/keys-service";
import { Loader } from "../../../styled-components/loader";
import { PageLayout } from "../../page-layout";
import PlanDetails from "../../plan-details/PlanDetaills";

export default function Keys() {
  const [open, setOpen] = useState(false);
  const [modalType, setModalType] = useState(0);
  const [apiKeys, setApiKeys] = useState([]);
  const [currentAPIKey, setCurrentAPIKey] = useState({ text: "", saved: false });
  const [loading, setLoading] = useState(false);

  const ModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 480,
    height: 379,
    backgroud: "#fff !important",
    boxShadow: 24,
    p: 4,
    borderRadius: 8,
    padding: 32,
  };

  const fetchAPIKeys = async () => {
    setLoading(true);
    try {
      const response = await getAPIKeys();
      const data = await response.data.map((item) => ({
        ...item,
        created_at: moment(item.created_at).format("Do MMMM YYYY"),
        updated_at: moment(item.updated_at).format("Do MMMM YYYY"),
      }));
      setApiKeys(data);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAPIKeys();
  }, []);

  const handleOpen = async (type, apiKey) => {
    setModalType(type);
    if (apiKey) setCurrentAPIKey(apiKey);
    setOpen(true);
  };

  const handleClose = (refetch, isWarning) => {
    setOpen(false);
    if (refetch) fetchAPIKeys();
    if (isWarning) {
      setModalType(3);
      setOpen(true);
    }
  };

  return (
    <PageLayout>
      <Box p="16px 25px" display="flex" flexDirection="column" gap="32px">
        <Typography fontWeight={600} fontSize={22} color="#272950" fontFamily="Raleway">
          API Keys
        </Typography>
        <Stack maxWidth="631px">
          <Typography
            fontWeight={400}
            fontSize={14}
            color="#272950"
            lineHeight="20px"
            fontFamily="Open Sans"
          >
            To begin integrating our API Service into your applications, you&lsquo;ll need to
            generate an API Key Token. This token must accompany all of your API requests for
            authentication and access purposes.
          </Typography>
        </Stack>
        {loading && <Loader />}
        {!loading && apiKeys?.length > 0 ? (
          <Stack maxHeight="1000px">
            <Typography
              fontWeight={500}
              fontSize="18px"
              lineHeight="28px"
              color="#6D6F96"
              mb="16px"
              fontFamily="Raleway"
            >
              Keys
            </Typography>
            <Box display="flex" alignItems="center" justifyContent="flex-end" mb="16px">
              <Button
                variant="outlined"
                sx={{
                  fontWeight: 400,
                  fontSize: "12px",
                  border: "1px solid #ECECEE",
                  color: "#272950",
                  "&:hover": { border: "1px solid #ECECEE" },
                }}
                onClick={() => handleOpen(0, null)}
              >
                <FiPlus color="#272950" size="20px" style={{ marginRight: "8px" }} />
                Create Key
              </Button>
            </Box>
            <TableContainer
              sx={{ border: "1px solid #E2E2E8", borderRadius: "12px", boxShadow: "none" }}
            >
              <Table>
                <TableHead sx={{ backgroundColor: "#f7f7f9" }}>
                  <TableRow>
                    <TableCell>
                      <TableHeaderText>Name</TableHeaderText>
                    </TableCell>
                    <TableCell>
                      <TableHeaderText>API Key</TableHeaderText>
                    </TableCell>
                    <TableCell>
                      <TableHeaderText>Status</TableHeaderText>
                    </TableCell>
                    <TableCell>
                      <TableHeaderText>Actions</TableHeaderText>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {apiKeys.map((apiKey) => (
                    <TableRow>
                      <TableCell>
                        <Stack direction="column">
                          <Typography
                            fontFamily="Open Sans"
                            fontWeight={700}
                            fontSize="16px"
                            color="#272950"
                            lineHeight="24px"
                          >
                            {apiKey.name}
                          </Typography>
                          <Typography
                            fontFamily="Open Sans"
                            fontWeight={400}
                            fontSize="16px"
                            color="#6F727A"
                            lineHeight="24px"
                          >
                            {apiKey.created_at}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell>
                        <Typography
                          fontFamily="Open Sans"
                          display="flex"
                          alignItems="center"
                          fontWeight={400}
                          color="#272950"
                          lineHeight="24px"
                          sx={{ wordBreak: "break-all", fontSize: "16px" }}
                        >
                          {apiKey.apikey}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          fontFamily="Open Sans"
                          fontWeight={600}
                          fontSize="12px"
                          color="#6F727A"
                          lineHeight="20px"
                          padding="8px 16px"
                          borderRadius="20px"
                          sx={{
                            backgroundColor: "#DCFBEA",
                            color: "#249F5D",
                            width: "fit-content",
                          }}
                        >
                          Live
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Stack direction="row" gap="20px" justifyContent="start">
                          <FiEdit
                            fontSize="15px"
                            color="#2E65F3"
                            cursor="pointer"
                            onClick={() => handleOpen(1, apiKey)}
                          />
                          <Divider
                            orientation="vertical"
                            sx={{ color: "#000", border: "1px solid #E0E2E7", height: "24px" }}
                          />
                          <RiDeleteBinFill
                            color="#F32D2D"
                            fontSize="15px"
                            cursor="pointer"
                            onClick={() => handleOpen(2, apiKey)}
                          />
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Typography
              fontWeight={500}
              fontSize="12px"
              lineHeight="18px"
              mt="16px"
              color="#061825"
              fontFamily="Open Sans"
            >
              API Key Usage:{" "}
              <span style={{ fontWeight: 400 }}>{`${apiKeys.length} of 3 Max Allowed`}</span>
            </Typography>
            <Stack mt="32px">
              <PlanDetails hideUpgradeButton />
            </Stack>
          </Stack>
        ) : (
          !loading && (
            <Stack
              alignItems="center"
              p="32px"
              gap="22px"
              maxWidth="631px"
              border="1px solid #ECECEE"
              borderRadius="5px"
            >
              <KeyIcon width={77} height={28} />
              <Stack width="282px" height="156px" alignItems="center">
                <Typography
                  color="#272950"
                  fontWeight={500}
                  fontSize="20px"
                  lineHeight="28px"
                  mb="12px"
                >
                  Create Your First API Key
                </Typography>
                <Typography
                  color="#272950"
                  fontWeight={400}
                  fontSize="14px"
                  lineHeight="28px"
                  textAlign="center"
                >
                  Orders that have been placed will appear here for easy management and tracking.
                </Typography>
              </Stack>
              <Button
                variant="outlined"
                sx={{
                  fontWeight: 400,
                  fontSize: "12px",
                  border: "1px solid #ECECEE",
                  color: "#272950",
                  "&:hover": { border: "1px solid #ECECEE" },
                }}
                onClick={() => handleOpen(0)}
              >
                <FiPlus color="#000" size="16px" style={{ marginRight: "8px" }} />
                Create API Key
              </Button>
            </Stack>
          )
        )}
      </Box>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Stack style={ModalStyle} sx={{ background: "#fff" }} justifyContent="space-between">
          {modalType === 0 && <ModalCreate fetchAPIKeys={fetchAPIKeys} handleClose={handleClose} />}
          {modalType === 1 && (
            <ModalEdit
              fetchAPIKeys={fetchAPIKeys}
              apiKey={currentAPIKey}
              handleClose={handleClose}
            />
          )}
          {modalType === 2 && (
            <ModalDelete
              fetchAPIKeys={fetchAPIKeys}
              apiKey={currentAPIKey}
              handleClose={handleClose}
            />
          )}
          {modalType === 3 && <ModalWarning handleClose={handleClose} />}
        </Stack>
      </Modal>
    </PageLayout>
  );
}

const TableHeaderText = styled(Typography)({
  fontWeight: "600",
  fontSize: "12px",
  fontFamily: "Open Sans",
  color: "#6F727A",
  lineHeight: "20px",
  pl: 1,
});
