import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_DEV_PORTAL_API_SERVER,
});

const moesifApiClient = axios.create({
  baseURL: process.env.REACT_APP_MOESIF_PROXY_URL,
});

const setAxiosTokenInterceptor = async (accessToken) => {
  console.log("Setting Axios Token Interceptor from service");
  apiClient.interceptors.request.use(
    async (config) => {
      const configs = { ...config };
      configs.headers.Authorization = `Bearer ${accessToken}`;

      return config;
    },
    (error) => Promise.reject(error)
  );
  moesifApiClient.interceptors.request.use(
    async (config) => {
      const configs = { ...config };
      configs.headers.Authorization = `Bearer ${accessToken}`;

      return config;
    },
    (error) => Promise.reject(error)
  );
};

export { apiClient, moesifApiClient, setAxiosTokenInterceptor };
