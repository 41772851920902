import { apiClient, moesifApiClient } from "./api-client-service";

export const registerStripeCustomer = async (checkoutSessionId) =>
  apiClient.post(`/register/stripe/${checkoutSessionId}`);

export const getStripeCustomer = async (email) => apiClient.get(`/stripe/customer?email=${email}`);

export const getPlan = async () => apiClient.get(`/stripe/plan`);

export const getPaymentMethods = async (customerId) =>
  moesifApiClient.get(`/stripe/customers/${customerId}/payment-methods`);

export const subscribeToFreePlan = async (email) =>
  moesifApiClient.post("/stripe/subscriptions/free-plan", {
    email,
  });

export const getAllPlans = async () => moesifApiClient.get("/stripe/plans");

export const updateSubscriptionPlan = async (subscriptionId, payload) =>
  moesifApiClient.put(`/stripe/subscriptions/${subscriptionId}`, payload);

export const getSubscriptionDetails = async (subscriptionId) =>
  moesifApiClient.get(`/stripe/subscriptions/${subscriptionId}`);

export const getLocalSubscriptionDetails = async () =>
  JSON.parse(localStorage.getItem("subscriptionDetails") || "{}");

export const setLocalSubscriptionDetails = async (subscriptionDetails) =>
  localStorage.setItem("subscriptionDetails", subscriptionDetails);
