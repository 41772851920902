import { Stack } from "@mui/material";
import React from "react";

import MoesifChart from "../../moesif/moesif-embedded-template";
import { PageLayout } from "../../page-layout";
import ActivityTable from "../../table/activity-table";

function AuthDashboard() {
  return (
    <PageLayout>
      <Stack direction="column" spacing="30px">
        <MoesifChart />
        <ActivityTable />
      </Stack>
    </PageLayout>
  );
}

export default function Dashboard() {
  return <AuthDashboard />;
}
