import { useAuth0 } from "@auth0/auth0-react";
import { Box, Stack } from "@mui/material";
import React from "react";

function DashboardHeader() {
  const { user: auth0User, logout } = useAuth0();
  const handleLogout = async () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };
  const user = auth0User;
  return (
    <Stack
      height={64}
      paddingInline="40px 25px"
      flexDirection="row"
      justifyContent="flex-end"
      alignItems="center"
      borderBottom="1px solid"
    >
      <Box display="flex" gap="25px" alignItems="center">
        <img
          src={user.picture}
          width={30}
          height={30}
          style={{ borderRadius: "100%", cursor: "pointer" }}
          onClick={handleLogout}
          alt="Log Out"
        />
      </Box>
    </Stack>
  );
}

export default DashboardHeader;
