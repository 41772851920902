import { Box, Modal, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import PricingModal from "./pricing-modal";
import PlanIcon from "../../assets/icons/plan";
import { getPlan } from "../../services/stripe-service";
import ButtonOutlineSecodary from "../../styled-components/button-outline-secodary";
import { Loader } from "../../styled-components/loader";

export default function PlanDetails({ hideUpgradeButton = false }) {
  const [plan, setPlan] = useState();
  const [loading, setLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const fetchPlan = async () => {
    setLoading(true);
    try {
      const response = await getPlan();
      setPlan(response.data);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPlan();
  }, []);

  const handleClose = (isUpdated = false) => {
    setIsOpenModal(false);
    if (isUpdated) {
      fetchPlan();
    }
  };

  return (
    <>
      <Typography
        fontWeight={500}
        fontSize="18px"
        lineHeight="28px"
        color="#6D6F96"
        fontFamily="Raleway"
      >
        Current Plan
      </Typography>
      {loading ? (
        <Loader />
      ) : (
        <Box
          bgcolor="#FBFBFB"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt="16px"
          p={2}
        >
          <Box display="flex" alignItems="center" gap="16px">
            <PlanIcon size={26} />
            <Stack>
              <Typography
                color="#6D6F96"
                fontWeight="700"
                fontSize="12px"
                lineHeight="20px"
                fontFamily="Open Sans"
              >
                {plan && plan.name}
              </Typography>
              <Typography
                color="#6D6F96"
                fontWeight="500"
                fontSize="12px"
                lineHeight="20px"
                fontFamily="Open Sans"
              >
                {plan && plan.description}
              </Typography>
            </Stack>
          </Box>
          {!hideUpgradeButton && (
            <ButtonOutlineSecodary
              style={{
                width: "max-content",
                padding: "11px 24px 9px 24px",
                verticalAlign: "middle",
                display: "flex",
                alignItems: "center",
                backgroundColor: "#fff",
              }}
              fontSize="12px"
              fontFamily="Open Sans"
              fontWeight="500"
              lineHeight="16px"
              onClick={() => setIsOpenModal(true)}
            >
              Upgrade
            </ButtonOutlineSecodary>
          )}
        </Box>
      )}
      <Modal
        keepMounted
        open={isOpenModal}
        onClose={() => handleClose(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Stack
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "950px",
            height: "669px",
            backgroud: "#fff !important",
            boxShadow: 24,
            p: 4,
            borderRadius: "8px",
            padding: 42,
          }}
          sx={{ background: "#fff" }}
          justifyContent="space-between"
        >
          <PricingModal handleClose={handleClose} currentPlan={plan} />
        </Stack>
      </Modal>
    </>
  );
}
