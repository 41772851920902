import ClearIcon from "@mui/icons-material/Clear";
import { Box, Divider, Typography } from "@mui/material";
import React from "react";

export default function ModalWarning({ handleClose }) {
  return (
    <Box>
      <Box display="flex" width="100%" alignItems="center" justifyContent="space-between">
        <Typography
          id="keep-mounted-modal-title"
          variant="h6"
          component="h2"
          fontWeight={500}
          fontSize="18px"
          lineHeight="28px"
          color="#e54d4d"
        >
          Warning
        </Typography>
        <ClearIcon
          onClick={() => handleClose()}
          sx={{ color: "#272950", cursor: "pointer" }}
          fontSize="medium"
        />
      </Box>
      <Divider sx={{ my: "16px" }} />
      <Typography fontSize={18} color="#000" fontWeight="500">
        You have reached the maximum number of keys
      </Typography>
    </Box>
  );
}
