import {
  Button,
  ButtonGroup,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { saveAs } from "file-saver";
import moment from "moment-timezone";
import React from "react";
import DatePicker, { DateObject } from "react-multi-date-picker";

import { downloadTableData, getTableData } from "../../services/moesif-service";
import ButtonOutlineSecodary from "../../styled-components/button-outline-secodary";
import { Loader } from "../../styled-components/loader";

function ActivityTable() {
  const [tableData, setTableData] = React.useState([]);
  const [params, setParams] = React.useState({
    time_zone: moment.tz.guess(),
    from: moment().subtract(1, "month").format("MM/DD/YYYY"),
    to: moment().format("MM/DD/YYYY"),
  });
  const [selectedDates, setSelectedDates] = React.useState([
    new DateObject(moment().subtract(1, "month").toDate()),
    new DateObject(moment().toDate()),
  ]);
  const [pagination, setPagination] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [loadingTableData, setLoadingTableData] = React.useState(false);

  const handleChange = (event) => {
    setSelectedDates(event);
  };

  const fetchTableData = async (query = params) => {
    try {
      setLoadingTableData(true);
      const response = await getTableData(query);
      setTableData(response.data.tableData);
      setPagination(response.data.pagination);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTableData(false);
    }
  };

  const downloadTableDataAsXlsx = async (queryParams) => {
    try {
      setLoading(true);
      const response = await downloadTableData(queryParams);
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const fileName = `moesif-${params.from}-${params.to}.xlsx`;
      saveAs(blob, fileName);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchTableData();
  }, []);

  React.useEffect(() => {
    setParams((prev) => ({
      ...prev,
      from: selectedDates[0].format("MM/DD/YYYY"),
      to: selectedDates[1]?.format("MM/DD/YYYY"),
    }));
  }, [selectedDates]);

  const handleCalenderClose = () => {
    fetchTableData();
  };

  const handlePreviousPagination = () => {
    if (pagination && pagination.previous) {
      fetchTableData({ ...params, after: pagination.previous, page: pagination.page - 1 });
    }
  };

  const handleNextPagination = () => {
    if (pagination && pagination.next) {
      fetchTableData({ ...params, after: pagination.next, page: pagination.page + 1 });
    }
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
        <Typography
          fontWeight={500}
          fontSize="18px"
          color="#6d6f96"
          fontFamily="Raleway"
          lineHeight="28px"
        >
          Activity
        </Typography>
        <Stack direction="row" gap="8px">
          <DatePicker
            onChange={handleChange}
            onClose={handleCalenderClose}
            range
            maxDate={Date.now()}
            showOtherDays
            rangeHover
            arrowClassName="date-range-picker-arrow"
            containerClassName="date-range-picker-container"
            className="date-range-picker"
            highlightToday={false}
            dateSeparator=" - "
            value={selectedDates}
            // value="2020/10/19"
            render={(value, openCalendar) => (
              <ButtonOutlineSecodary
                style={{
                  minHeight: "46px",
                  fontSize: "12px",
                }}
                type="button"
                onClick={openCalendar}
              >
                {value && value !== "" ? value.toString() : "Date"}
              </ButtonOutlineSecodary>
            )}
          />
          <button
            style={{
              color: "#272950",
              backgroundColor: "transparent",
              border: "1px solid #E2E2E8",
              outline: "none",
              userSelect: "none",
              cursor: loading ? "default" : "pointer",
              borderRadius: "5px",
              padding: "8px",
            }}
            type="button"
            onClick={() => downloadTableDataAsXlsx(params)}
            disabled={loading}
          >
            {loading && (
              <Loader
                sx={{
                  height: "24px !important",
                  width: "24px !important",
                  color: "#272950 !important",
                }}
              />
            )}
            {!loading && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M11 10L6 10L12 16L18 10L13 10L13 3L11 3L11 10ZM20 19L4 19L2 19L2 20C2 20.2652 2.10536 20.5196 2.29289 20.7071C2.48043 20.8946 2.73478 21 3 21L21 21C21.2652 21 21.5196 20.8946 21.7071 20.7071C21.8946 20.5196 22 20.2652 22 20L22 19L20 19Z"
                  fill="#272950"
                />
              </svg>
            )}
          </button>
        </Stack>
      </Stack>
      <Stack
        direction="column"
        sx={{ m: "0 !important", mt: "15px !important" }}
        alignItems="center"
      >
        <TableContainer
          sx={{
            border: "1px solid #E2E2E8",
            borderRadius: "5px",
            boxShadow: "none",
            padding: "15px",
            paddingTop: 0,
            maxHeight: "500px",
            fontFamily: "Open Sans",
            fontSize: "12px",
            lineHeight: "20px",
          }}
          component={Paper}
        >
          <Table sx={{ minWidth: 650, backgroundColor: "white" }} ariaLabel="activity table">
            <TableHead sx={{ position: "sticky", top: 0, backgroundColor: "white" }}>
              <TableRow>
                <TableCell
                  sx={{
                    padding: "15px 8px",
                    fontFamily: "Open Sans",
                    fontSize: "12px",
                    lineHeight: "20px",
                    fontWeight: 600,
                  }}
                >
                  TimeStamp(UTC)
                </TableCell>
                <TableCell
                  sx={{
                    padding: "15px 8px",
                    fontFamily: "Open Sans",
                    fontSize: "12px",
                    lineHeight: "20px",
                    fontWeight: 600,
                  }}
                >
                  Referrer IP
                </TableCell>
                <TableCell
                  sx={{
                    padding: "15px 8px",
                    fontFamily: "Open Sans",
                    fontSize: "12px",
                    lineHeight: "20px",
                    fontWeight: 600,
                  }}
                >
                  Action
                </TableCell>
                <TableCell
                  sx={{
                    padding: "15px 8px",
                    fontFamily: "Open Sans",
                    fontSize: "12px",
                    lineHeight: "20px",
                    fontWeight: 600,
                  }}
                >
                  Data
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loadingTableData && (
                <TableRow>
                  <TableCell
                    colSpan={4}
                    scope="row"
                    sx={{
                      padding: "15px 8px",
                      fontFamily: "Open Sans",
                      fontSize: "12px",
                      lineHeight: "20px",
                      fontWeight: 600,
                      textAlign: "center",
                    }}
                  >
                    <Loader />
                  </TableCell>
                </TableRow>
              )}
              {!loadingTableData && (!tableData || tableData.length < 1) && (
                <TableRow>
                  <TableCell
                    colSpan={4}
                    scope="row"
                    sx={{
                      padding: "15px 8px",
                      fontFamily: "Open Sans",
                      fontSize: "12px",
                      lineHeight: "20px",
                      fontWeight: 600,
                      textAlign: "center",
                    }}
                  >
                    No data available
                  </TableCell>
                </TableRow>
              )}
              {!loadingTableData &&
                tableData?.length > 0 &&
                tableData.map((row) => (
                  <TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell
                      sx={{
                        padding: "15px 8px",
                        fontFamily: "Open Sans",
                        fontSize: "12px",
                        lineHeight: "20px",
                        fontWeight: 600,
                      }}
                      component="th"
                      scope="row"
                    >
                      {row.requestTime}
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "15px 8px",
                        fontFamily: "Open Sans",
                        fontSize: "12px",
                        lineHeight: "20px",
                        fontWeight: 600,
                      }}
                    >
                      {row.referrerIP}
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "15px 8px",
                        fontFamily: "Open Sans",
                        fontSize: "12px",
                        lineHeight: "20px",
                        fontWeight: 600,
                      }}
                    >
                      {row.action}
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "15px 8px",
                        fontFamily: "Open Sans",
                        fontSize: "12px",
                        lineHeight: "20px",
                        fontWeight: 600,
                        wordBreak: "break-word",
                        maxWidth: "200px",
                      }}
                    >
                      {row.payload}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <ButtonGroup
          direction="row"
          variant="outlined"
          aria-label="Basic button group"
          sx={{ mt: "15px" }}
        >
          <Button
            style={{
              color: "#272950",
              backgroundColor: "transparent",
              border: "1px solid #E2E2E8",
              outline: "none",
              userSelect: "none",
              cursor: "pointer",
              padding: "8px",
              fontFamily: "Raleway",
              fontSize: "12px",
              lineHeight: "24px",
              minWidth: "100px",
              fontWeight: 600,
              borderTopLeftRadius: "5px",
              borderBottomLeftRadius: "5px",
            }}
            disabled={!(pagination && pagination.previous)}
            onClick={handlePreviousPagination}
          >
            Previous
          </Button>
          <Button
            style={{
              color: "#272950",
              backgroundColor: "transparent",
              border: "1px solid #E2E2E8",
              outline: "none",
              userSelect: "none",
              cursor: "pointer",
              padding: "8px",
              fontFamily: "Raleway",
              fontSize: "12px",
              lineHeight: "24px",
              minWidth: "100px",
              fontWeight: 600,
              borderTopRightRadius: "5px",
              borderBottomRightRadius: "5px",
            }}
            disabled={!(pagination && pagination.next)}
            onClick={handleNextPagination}
          >
            Next
          </Button>
        </ButtonGroup>
      </Stack>
    </>
  );
}

export default ActivityTable;
