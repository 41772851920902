import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useSearchParams } from "react-router-dom";

import { PageLoader } from "./page-loader";
import {
  getStripeCustomer,
  getSubscriptionDetails,
  registerStripeCustomer,
  setLocalSubscriptionDetails,
} from "../services/stripe-service";

export function PaymentGuard() {
  const [searchParams] = useSearchParams();
  const { user } = useAuth0();

  const [subscriptionDetails, setSubscriptionDetails] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const checkoutSessionId = searchParams.get("checkout-session");

  const getStripeCustomerDetails = async () => {
    try {
      setIsLoading(true);
      if (checkoutSessionId) {
        const stripeCustomerResponse = await registerStripeCustomer(checkoutSessionId);
        const stripeSubscriptionDetails = await getSubscriptionDetails(
          stripeCustomerResponse.data.subscription
        );
        const result = stripeSubscriptionDetails.data;
        setSubscriptionDetails(result.subscription);
        setLocalSubscriptionDetails(JSON.stringify(result.subscription));
      } else {
        const stripeCustomerResponse = await getStripeCustomer(user.email);
        const stripeSubscriptionDetails = await getSubscriptionDetails(
          stripeCustomerResponse.data.subscription_id
        );
        const result = stripeSubscriptionDetails.data;
        setSubscriptionDetails(result.subscription);
        setLocalSubscriptionDetails(JSON.stringify(result.subscription));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      getStripeCustomerDetails();
    }, 200);
  }, [checkoutSessionId, user]);

  if (isLoading) {
    return <PageLoader />;
  }

  if (subscriptionDetails?.status !== "active") {
    return <Navigate to="/pricing" replace />;
  }

  return <Outlet />;
}
