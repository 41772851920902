import React from "react";

export default function DashboardIcon({ size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.54005 3.55096H6.54004V3.55325V4.81992C6.54004 5.46272 6.37145 5.87141 6.12149 6.12137C5.87153 6.37133 5.46284 6.53992 4.82004 6.53992H3.55337C2.91057 6.53992 2.50189 6.37133 2.25193 6.12137C2.00197 5.87141 1.83337 5.46272 1.83337 4.81992V3.55325C1.83337 2.91046 2.00195 2.50205 2.25237 2.2522C2.50296 2.00216 2.91332 1.83325 3.56004 1.83325H4.82671C5.46969 1.83325 5.878 2.00193 6.12693 2.25141C6.37565 2.50069 6.54299 2.90858 6.54005 3.55096Z"
        fill="white"
        stroke="#6D6F96"
      />
      <path
        d="M12.4467 1.33325H11.18C9.72669 1.33325 8.96002 2.09992 8.96002 3.55325V4.81992C8.96002 6.27325 9.72669 7.03992 11.18 7.03992H12.4467C13.9 7.03992 14.6667 6.27325 14.6667 4.81992V3.55325C14.6667 2.09992 13.9 1.33325 12.4467 1.33325Z"
        fill="#6D6F96"
      />
      <path
        d="M11.18 9.45361H12.4467C13.0895 9.45361 13.4982 9.62221 13.7481 9.87217C13.9981 10.1221 14.1667 10.5308 14.1667 11.1736V12.4403C14.1667 13.0831 13.9981 13.4918 13.7481 13.7417C13.4982 13.9917 13.0895 14.1603 12.4467 14.1603H11.18C10.5372 14.1603 10.1285 13.9917 9.87858 13.7417C9.62862 13.4918 9.46002 13.0831 9.46002 12.4403V11.1736C9.46002 10.5308 9.62862 10.1221 9.87858 9.87217C10.1285 9.62221 10.5372 9.45361 11.18 9.45361Z"
        fill="white"
        stroke="#6D6F96"
      />
      <path
        d="M4.82671 8.95361H3.56004C2.10004 8.95361 1.33337 9.72028 1.33337 11.1736V12.4403C1.33337 13.9003 2.10004 14.6669 3.55337 14.6669H4.82004C6.27337 14.6669 7.04004 13.9003 7.04004 12.4469V11.1803C7.04671 9.72028 6.28004 8.95361 4.82671 8.95361Z"
        fill="#6D6F96"
      />
    </svg>
  );
}
