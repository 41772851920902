import ClearIcon from "@mui/icons-material/Clear";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import { Box, Divider, Typography, OutlinedInput, Button } from "@mui/material";
import React, { useState, useEffect } from "react";

import { updateAPIKey } from "../../../services/keys-service";

export default function ModalEdit({ apiKey, handleClose }) {
  const [tempApi, setTempApi] = useState({
    name: "",
    apikey: "",
  });

  const [isUpdated, setIsUpdated] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTempApi(apiKey);
    setIsUpdated(false);
  }, []);

  const handleEdit = async () => {
    setLoading(true);
    try {
      const requestBody = {
        name: tempApi?.name,
      };
      await updateAPIKey(apiKey?.apikey, requestBody);
      setIsUpdated(true);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box>
        <Box display="flex" width="100%" alignItems="center" justifyContent="space-between">
          <Typography
            id="keep-mounted-modal-title"
            variant="h6"
            component="h2"
            fontWeight={500}
            fontSize="18px"
            lineHeight="28px"
            color="#6D6F96"
          >
            Edit API Key
          </Typography>
          <ClearIcon
            onClick={() => handleClose(false)}
            sx={{ color: "#272950", cursor: "pointer" }}
            fontSize="medium"
          />
        </Box>
        <Divider sx={{ my: "16px" }} />
        <Box display="flex" flexDirection="column" gap="16px">
          {isUpdated ? (
            <Box
              display="flex"
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
              gap="12px"
            >
              <Typography
                id="keep-mounted-modal-description"
                fontWeight={500}
                fontSize="20px"
                color="#6D6F96"
                lineHeight="28px"
              >
                Complete
              </Typography>
              <Typography
                id="keep-mounted-modal-description"
                fontWeight={400}
                fontSize="12px"
                color="#6D6F96"
                lineHeight="20px"
              >
                Your API Key has been successfully edited.
              </Typography>
              <DoneRoundedIcon sx={{ fontSize: "76px", color: "#15E5A7", fontWeight: "800" }} />
            </Box>
          ) : (
            <>
              <Box>
                <Typography
                  id="keep-mounted-modal-description"
                  fontWeight={400}
                  fontSize="14px"
                  color="#A1A2BD"
                  lineHeight="20px"
                >
                  API Key
                </Typography>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type="text"
                  placeholder="e.g. Edit API Name"
                  sx={{
                    fontSize: "14px",
                    height: "36px",
                    width: "100%",
                    mt: "10px",
                  }}
                  disabled
                  value={tempApi?.apikey}
                  readOnly
                />
              </Box>
              <Box>
                <Typography
                  id="keep-mounted-modal-description"
                  fontWeight={400}
                  fontSize="14px"
                  color="#A1A2BD"
                  lineHeight="20px"
                >
                  Name
                </Typography>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type="text"
                  placeholder="e.g. Edit API Key"
                  sx={{
                    fontSize: "14px",
                    height: "36px",
                    width: "100%",
                    mt: "10px",
                  }}
                  value={tempApi?.name}
                  onChange={(e) => {
                    setTempApi((prevTempApi) => ({
                      ...prevTempApi,
                      name: e.target.value,
                    }));
                  }}
                />
              </Box>
            </>
          )}
        </Box>
      </Box>
      {isUpdated ? (
        <Button
          onClick={() => {
            handleClose(true);
            setIsUpdated(false);
          }}
          variant="outlined"
          sx={{
            width: "100%",
            border: "1px solid #061825",
            color: "#061825",
            fontSize: "12px",
            fontWeight: 400,
            "&:hover": { border: "1px solid #061825" },
          }}
        >
          Close
        </Button>
      ) : (
        <Button
          onClick={handleEdit}
          variant="outlined"
          sx={{
            width: "100%",
            border: "1px solid #061825",
            color: "#061825",
            fontSize: "12px",
            fontWeight: 400,
            "&:hover": { border: "1px solid #061825" },
          }}
          loading={loading}
        >
          Save Changes
        </Button>
      )}
    </>
  );
}
