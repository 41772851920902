import { useAuth0 } from "@auth0/auth0-react";
import { Box, Modal, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FiPlus } from "react-icons/fi";

import PaymentMethodModal from "./payment-method-modal";
import masterCardImg from "../../../assets/icons/MasterCard.png";
import { getPaymentMethods, getStripeCustomer } from "../../../services/stripe-service";
import ButtonOutlineSecodary from "../../../styled-components/button-outline-secodary";
import { Loader } from "../../../styled-components/loader";

export default function PaymentMethods() {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useAuth0();
  const [isOpenModal, setIsOpenModal] = useState(false);

  const fetchPaymentMethods = async () => {
    setLoading(true);
    try {
      const customer = await getStripeCustomer(user.email);
      const response = await getPaymentMethods(customer.data.customer_id);
      setPaymentMethods(response.data.paymentMethods);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const openStripeManagement = () => {
    window.open(
      `${process.env.REACT_APP_STRIPE_MANAGEMENT_URL}?prefilled_email=${user.email}`,
      "_blank",
      "noreferrer"
    );
  };

  useEffect(() => {
    fetchPaymentMethods();
  }, []);

  const handleClose = () => {
    setIsOpenModal(false);
  };

  return (
    <>
      <Typography
        fontWeight={500}
        fontSize="18px"
        lineHeight="28px"
        color="#6D6F96"
        fontFamily="Raleway"
      >
        Payment methods
      </Typography>
      {loading && <Loader sx={{ mt: "16px" }} />}
      {!loading && paymentMethods && paymentMethods.length > 0
        ? paymentMethods.map((method) => (
            <Box
              bgcolor="#FBFBFB"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={2}
              marginTop="16px"
            >
              <Box display="flex" alignItems="center" gap="16px">
                <img src={masterCardImg} width="26px" height="26px" alt="plan" />
                <Stack>
                  <Typography
                    color="#6D6F96"
                    fontWeight="700"
                    fontSize="12px"
                    lineHeight="20px"
                    fontFamily="Open Sans"
                  >
                    .... .... .... {method.card.last4}
                  </Typography>
                  <Typography
                    color="#6D6F96"
                    fontWeight="500"
                    fontSize="12px"
                    lineHeight="20px"
                    fontFamily="Open Sans"
                  >
                    {method.card.exp_month}/{method.card.exp_year}
                  </Typography>
                </Stack>
              </Box>
            </Box>
          ))
        : !loading && (
            <Typography
              color="#6D6F96"
              fontWeight="700"
              fontSize="12px"
              lineHeight="20px"
              fontFamily="Open Sans"
              marginTop="16px"
            >
              No payment methods added
            </Typography>
          )}
      {!loading && (
        <ButtonOutlineSecodary
          style={{
            marginTop: "32px",
            width: "max-content",
            padding: "8px 20px",
            verticalAlign: "middle",
            display: "flex",
            alignItems: "center",
          }}
          fontSize="12px"
          fontFamily="Open Sans"
          fontWeight="400"
          onClick={openStripeManagement}
        >
          <FiPlus size="20px" style={{ marginRight: "8px" }} />
          Add New Card
        </ButtonOutlineSecodary>
      )}
      <Modal
        keepMounted
        open={isOpenModal}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Stack
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "480px",
            height: "669px",
            backgroud: "#fff !important",
            boxShadow: 24,
            p: 4,
            borderRadius: "8px",
            padding: 32,
          }}
          sx={{ background: "#fff" }}
          justifyContent="space-between"
        >
          <PaymentMethodModal handleClose={handleClose} />
        </Stack>
      </Modal>
    </>
  );
}
