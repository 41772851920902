import React, { createContext, useContext } from "react";

import { apiClient, moesifApiClient } from "./services/api-client-service";

const ApiClientContext = createContext();

export function ApiClientProvider({ children }) {
  return (
    <ApiClientContext.Provider value={{ apiClient, moesifApiClient }}>
      {children}
    </ApiClientContext.Provider>
  );
}

export const useApiClient = () => useContext(ApiClientContext);
