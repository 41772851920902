import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Navigate } from "react-router-dom";

import { PageLoader } from "./page-loader";
import { setAxiosTokenInterceptor } from "../services/api-client-service";

export function AuthenticationGuard({ children }) {
  const { getAccessTokenSilently, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  const setAxiosTokenInterceptors = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      await setAxiosTokenInterceptor(accessToken);
      return accessToken;
    } catch (error) {
      loginWithRedirect({
        appState: {
          returnTo: window.location.pathname,
        },
      });
      return null;
    }
  };

  React.useEffect(() => {
    if (isAuthenticated) setAxiosTokenInterceptors();
  }, [isAuthenticated]);

  if (isLoading) {
    return <PageLoader />;
  }

  if (!isAuthenticated) return <Navigate to="/auth/login" replace />;

  return children;
}
