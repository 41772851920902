import DoneIcon from "@mui/icons-material/Done";
import { Box, Stack, Switch, Typography } from "@mui/material";
import React from "react";

import {
  getAllPlans,
  getLocalSubscriptionDetails,
  updateSubscriptionPlan,
} from "../../services/stripe-service";
import ButtonOutlineSecodary from "../../styled-components/button-outline-secodary";
import { Loader } from "../../styled-components/loader";

export default function PricingModal({ handleClose, currentPlan }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [plans, setPlans] = React.useState([]);

  const fetchAllPlans = async () => {
    setIsLoading(true);
    try {
      const response = await getAllPlans();
      setPlans(response.data.plans);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePlanChange = async (plan) => {
    setLoading(true);
    try {
      const subscription = await getLocalSubscriptionDetails();
      console.log(`\n  subscription:`, subscription);
      await updateSubscriptionPlan(subscription.id, {
        items: [{ id: subscription.items.data[0].id, price: plan?.id }],
      });
      handleClose(true);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchAllPlans();
  }, []);

  return (
    <Stack gap="22px">
      <Stack gap="22px" alignItems="center">
        <Stack gap="14px" alignItems="center">
          <Typography
            id="keep-mounted-modal-title"
            variant="h6"
            component="h2"
            fontWeight={400}
            fontSize="36px"
            lineHeight="32px"
            color="#061825"
          >
            Flexible plans to help you scale
          </Typography>
          <Typography
            id="keep-mounted-modal-title"
            variant="h6"
            component="h2"
            fontWeight={400}
            fontSize="16px"
            lineHeight="32px"
            color="#272950"
          >
            Choose the plan that fits your needs best, or reach out to us for a personalized
            solution.
          </Typography>
        </Stack>
        <Stack direction="row" gap="24px">
          <Typography
            id="keep-mounted-modal-title"
            variant="h6"
            component="h2"
            fontWeight={700}
            fontSize="16px"
            lineHeight="32px"
            color="#181059"
          >
            Monthly
          </Typography>
          <Switch />
          <Typography
            id="keep-mounted-modal-title"
            variant="h6"
            component="h2"
            fontWeight={400}
            fontSize="16px"
            lineHeight="32px"
            color="#181059"
          >
            Yearly (20% off)
          </Typography>
        </Stack>
      </Stack>
      <Stack gap="16px" justifyContent="center" direction="row">
        {!isLoading &&
          plans.map((plan) => (
            <Box padding="8px" height="433px" width="276px" key={plan.id}>
              <Box borderRadius="24px" border="1px solid" padding="24px" height="100%">
                <Stack justifyContent="space-between" height="100%">
                  <Stack gap="22px">
                    <Typography
                      fontWeight={600}
                      fontSize="22px"
                      lineHeight="24px"
                      color="#181059"
                      fontFamily="Raleway"
                    >
                      {plan.product.name}
                    </Typography>
                    <Stack gap="15px" direction="column">
                      {plan.product.marketing_features &&
                        plan.product.marketing_features.length > 0 &&
                        plan.product.marketing_features.map((feature) => (
                          <Stack gap="12px" direction="row" key={feature.name}>
                            <Box
                              display="flex"
                              width="16px"
                              height="16px"
                              alignItems="center"
                              justifyContent="center"
                              marginTop="4px"
                            >
                              <DoneIcon sx={{ color: "#061825", fontWeight: "bold" }} />
                            </Box>
                            <Typography
                              fontWeight={400}
                              fontSize="16px"
                              lineHeight="20px"
                              color="#181059"
                              fontFamily="Inter"
                            >
                              {feature.name}
                            </Typography>
                          </Stack>
                        ))}
                    </Stack>
                  </Stack>
                  <Stack gap="22px">
                    <Stack gap="8px" direction="row" alignItems="baseline">
                      <Typography
                        fontWeight={500}
                        fontSize="22px"
                        lineHeight="22px"
                        color="#181059"
                        fontFamily="Raleway"
                      >
                        <span
                          style={{
                            fontFamily: "Raleway",
                            fontSize: "17px",
                            lineHeight: "20px",
                            fontWeight: 500,
                          }}
                        >
                          $
                        </span>
                        {plan.amount / 100}
                      </Typography>
                      <Typography
                        fontWeight={400}
                        fontSize="16px"
                        lineHeight="20px"
                        color="#181059"
                        fontFamily="Inter"
                      >
                        /month
                      </Typography>
                    </Stack>
                    <ButtonOutlineSecodary
                      style={{
                        borderRadius: "8px",
                        padding: "11px 37px",
                        color: "#061825",
                        fontWeight: 400,
                      }}
                      fontSize="14px"
                      fontFamily="Open Sans"
                      lineHeight="20px"
                      disabled={currentPlan?.id === plan.product.id || loading}
                      onClick={() => handlePlanChange(plan)}
                    >
                      {currentPlan?.id === plan.product.id ? "Current Plan" : "Choose"}
                    </ButtonOutlineSecodary>
                  </Stack>
                </Stack>
              </Box>
            </Box>
          ))}
        {isLoading && <Loader />}
      </Stack>
    </Stack>
  );
}
