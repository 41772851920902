import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";

import Auth0Login from "../components/pages/login/Auth0Login";

export default function AuthenticationRoutes() {
  return (
    <Routes>
      <Route path="" element={<Outlet />}>
        <Route path="login" element={<Auth0Login />} />
      </Route>
    </Routes>
  );
}
